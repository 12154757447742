import React, { useContext, useMemo } from 'react'
import CustomSelect from '../UI/inputs/CustomSelect'
import { AnnotationContext } from '../../context/AnnotationContext'


const options = [
    {value: 'kitchen', label:'Kitchen'},
    {value: 'bathroom', label:'Bathroom'},
    {value: 'dining', label:'Dining'}
]

const RoomTypeSelector = () => {

    const {config, updateImageQuality} = useContext(AnnotationContext)

    const handleChange = (e) => {
        const newQualityData = {roomType: e.target.value}
        const currentAssetID = config.currentAssetID;
        updateImageQuality(currentAssetID, newQualityData)
    }

    const currentValue = useMemo(() => {
        const currentID = config.currentAssetID;
        if (currentID && config.imageQuality[currentID]) {
            return config.imageQuality[currentID].roomType
        }
        return ''
    }, [config])

    return (
        <CustomSelect
            options={options}
            value={currentValue}
            defaultValue={'Room type for asset'}
            onChange={handleChange}
        />
    )
}

export default RoomTypeSelector
