import React from 'react'
import { Elevation } from './ElevationWrapper.styles'
import PropTypes from 'prop-types'

const ElevationWrapper = ({children, level=10, style}) => {
    return (
        <Elevation level={level} style={{...style}}>
            {children}
        </Elevation>
    )
}

ElevationWrapper.propTypes = {
    level: PropTypes.oneOf([10, 9, 8, 7, 6, 5, 4, 3, 2, 1]),
}

export default ElevationWrapper
