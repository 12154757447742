import React from 'react'
import { StyledTable } from './StyledTable.styles'

const CustomTable = ({children, ...props}) => {
    return (
        <StyledTable {...props}>
            {children}
        </StyledTable>
    )
}

export default React.memo(CustomTable)
