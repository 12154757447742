import styled from "styled-components";


export const StyledSelect = styled.select.withConfig({
    shouldForwardProp: props => !['fullWidth'].includes(props)
})`
    position: relative;
    width: ${({fullWidth}) => fullWidth ? '100%' : 'auto'};
    padding: 0.5vw 1vh;
    background-color: var(--neutral-light);
    border: 1px solid var(--primary-color);
    border-radius: 1vh;
    color: var(--text-color);
    transition: all 0.2s ease-in-out;
  
    &:focus {
        border-color: var(--secondary-color);
        box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
        outline: none;
    }

    &:focus-visible {
        outline: none;
    }
`

export const StyledOption = styled.option`
    background-color: transparent;
    color: var(--text-color);
`