import styled from "styled-components";


export const DropdownContainer = styled.div`
    position: relative;
    width: 100%;
    display: block;
`;

export const ToggleContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 0.2vw;
    background-color: transparent;
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (props) => !['isOpen', 'maxHeight'].includes(props)
})`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    overflow: hidden;
    // max-height: ${({isOpen, maxHeight}) => isOpen ? `${maxHeight}px` : '0' };
    opacity: ${({isOpen}) => isOpen ? 1 : 0 };
    padding: 0.5vw 1vh;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    transition: 
        max-height 0.3s ease, 
        opacity 0.3s ease;
`;