import React from 'react'
import { CarouselContainer, ControlButton } from './ImageCarousel.styles'
import AnnotationDrawer from '../AnnotationDrawer'
import { StaticIcon } from '../UI/icons/Icons.styles'

const ImageCarousel = ({showOriginal, currentPair, handleBack, handleNext}) => {
    const {originalPhoto, newPhoto} = currentPair 
    return (
        <CarouselContainer>
            <ControlButton onClick={() => handleBack()}>
                <StaticIcon className='bi bi-chevron-left h3'/>
            </ControlButton>
                    
            <AnnotationDrawer showOriginal={showOriginal} originalAsset={originalPhoto} newAsset={newPhoto}/>
            
            <ControlButton onClick={() => handleNext()}>
                <StaticIcon className='bi bi-chevron-right h3'/>
            </ControlButton>
        </CarouselContainer>
    )
}

export default React.memo(ImageCarousel)
