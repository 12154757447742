import React from "react";
import { useState } from "react";

export const useFetching = (callback) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const fetch = async (...args) => {
        try {
            await callback(...args)
        } catch (e) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }
    return [fetch, loading, error]
}