import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createMigrate from "redux-persist/es/createMigrate";
import storage from "redux-persist/lib/storage";
import annotationsReducer from "./reducers/annotationsReducer";
import persistReducer from "redux-persist/es/persistReducer";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import usersReducer from "./reducers/usersReducer";


const migrations = {
    0: (state) => {
        return {
            ...state
        }
    }
};

const persistConfig = {
    key: 'easyrentorAnnotator',
    version: 2,
    storage,
    migrate: createMigrate(migrations)
};

const rootReducer = combineReducers({
    // annotations: annotationsReducer,
    users: usersReducer,
    // add more reducers here
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }),
})

export const persistor = persistStore(store);