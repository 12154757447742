import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFetching } from '../hooks/useFetching'
import EasyRentorService from '../api/EasyRentorService'
import { Form, InputGroup, Spinner, Table } from 'react-bootstrap'
import { formatDate } from '../utils/utils'
import { Link } from 'react-router-dom'
import CustomBadge from '../components/UI/badges/CustomBadge'
import CustomDataTable from '../components/CustomDataTable'
import TextInput from '../components/UI/inputs/TextInput'
import ElevationWrapper from '../components/UI/wrappers/ElevationWrapper'
import { debounce } from 'lodash'
import { REPORT_STATUS } from '../enums'


const Reports = () => {

    const [searchString, setSearchString] = useState('')

    const [reports, setReports] = useState([])
    const [fetchReports, loading, error] = useFetching( async () => {
        const response = await EasyRentorService.getAllReports()
        const reports = response.data.content
        const filtered = reports.filter(report => report.reportStatus === REPORT_STATUS.WAITING_FOR_ANNOTATING)
        setReports(filtered)
    })

    const debounceSearchChange = useCallback(debounce((value) => {
        setSearchString(value)
    }, 300), [])

    const handleSearchChange = (event) => {
        debounceSearchChange(event.target.value)
    }

    
    useEffect(() => {
        fetchReports()
    }, [])

    const searchFilteredContracts = useMemo(() => {
        return reports.filter(report => report.reportId.includes(searchString)) || [];
    }, [reports, searchString])

    const tableColumns = useMemo(() => [
        {Header: 'UUID', accessor: 'reportId', Cell: ({value}) => <Link to={`/annotations/${value}`}>{value}</Link>},
        {Header: 'Country,city', accessor: 'city'},
        {Header: 'Report status', accessor: 'reportStatus', Cell: ({value}) => <CustomBadge type={'warning'}> {value} </CustomBadge>},
        {Header: 'Asset num', accessor: 'assetAmount'},
        {Header: 'startDate', accessor: 'startDate', Cell: ({value}) => formatDate(value)},
        {Header: 'endDate', accessor: 'endDate', Cell: ({value}) => formatDate(value)},
    ], [])
    

    return (
        <div className='container' style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <h1 style={{textAlign:'center'}}> Reports </h1>

            {/* Search input here */}
            <ElevationWrapper>
                <TextInput placeholder={'Search by UUID...'} fullWidth={true} onChange={(e) => {handleSearchChange(e)}}/>
            </ElevationWrapper>

            {loading 
                ? <Spinner animation='border' style={{width: '10vw', height:'10vw', alignSelf:'center'}} />
                : (
                    <CustomDataTable columns={tableColumns} data={searchFilteredContracts}/>
                )
            }
            

        </div>
    )
}

export default Reports
