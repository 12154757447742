

export const formatDate = (dateStr) => {
    const options = { 
        day: 'numeric', 
        month: 'numeric',
        year: 'numeric', 
        
        // hour: '2-digit', 
        // minute: '2-digit', 
        // second: '2-digit' 
    };
    const formatedDate = new Date(dateStr).toLocaleString("en-US", options)
    return formatedDate
}


export const isAnnotationsEmpty = (obj) => {
    if (!obj || Object.keys(obj).length === 0) {
        return true;
    }
    for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
        if (!isAnnotationsEmpty(obj[key])) {
            return false;
        }
        } else {
        return false;
        }
    }
    return true;
};


export const getDamageColor = (type) => {
    const DAMAGE_COLORMAP = {
        "SCRATCH": "rgb(255,0,0)",
        "SPOT": "rgb(0,0,255)",
        "OTHER": "rgb(0,255,0)",
        "DEFAULT": "rgb(0, 0, 0)",
    }

    try {
        return DAMAGE_COLORMAP[type]
    } catch (e) {
        console.log(`DAMAGE_COLOR ${type} is not in MAP`)
        return DAMAGE_COLORMAP.DEFAULT
    }
}

export const capitalizeString = (str) => {
    return str.charAt(0) + str.slice(1).toLowerCase()
}

export const getBackgroundColor = (type) => {
    switch (type) {
        case 'primary': return "var(--primary-color)";
        case 'secondary': return "var(--secondary-color)";
        case 'third': return "var(--third-color)";
        case 'accent': return "var(--accent-color)";
        case 'success': return "var(--success-color)";
        case 'warning': return "var(--warning-color)";
        case 'danger': return "var(--danger-color)";
        default: return "var(--primary-color)";
    }
}

export const denormalizeDimension = (normalizedValue, dimensionSize) => {
    return normalizedValue * dimensionSize
}


export const organizeAnnotationsFilters = (data) => {
    const result = {};
  
    for (const assetId in data) {
      const annotators = data[assetId];
  
        for (const email in annotators) {
            const annotationTypes = annotators[email];
    
            if (!result[email]) {
                result[email] = {};
            }
    
            for (const damageClass in annotationTypes) {
                if (!result[email][damageClass]) {
                    result[email][damageClass] = [];
                }
        
                result[email][damageClass] = [
                    ...result[email][damageClass],
                    ...annotationTypes[damageClass]
                ];
            }
        }
        }
    
    return result;
};


export const getFlattenAnnotations = (data) => {
    const flattened = [];

    const traverse = (node) => {
        if (Array.isArray(node)) {
            flattened.push(...node)
        } else if (typeof node === 'object') {
            Object.values(node).forEach(traverse)
        }
    }
    traverse(data)
    return flattened
}