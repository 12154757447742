import styled from "styled-components";
import { Table } from "react-bootstrap";


export const StyledTable = styled(Table)`
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    th, td {
        padding: 0.5vw 1vh;
        background-color: transparent;
        border: none;
        color: var(--text-color);
        text-align: left;
    }

    th {
        background-color: rgba(126, 82, 160, 0.3);
        font-weight: bold;
    }

    th.min-width, td.min-width {
        width: auto;  /* Allows these columns to expand as needed */
        white-space: nowrap;
    }

    th.fixed-width, td.fixed-width {
        width: 20%;  /* or any other fixed width */
    }

    td {
        // white-space: nowrap;
        // min-width: fit-content;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid rgba(126, 82, 160, 0.3); //  line between rows
    }

    td:not(:last-child) {
        border-right: 1px solid rgba(126, 82, 160, 0.05); //  column separators
    }

    tr:hover {
        background-color: rgba(126, 82, 160, 0.6); 
    }
`

export const CellWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`