import Layout from "../pages/Layout";
import Home from "../pages/Home";
import Annotations from "../pages/Annotations";
import NotFound from "../pages/NotFound";
import Reports from "../pages/Reports";
import TestAuth from "../pages/TestAuth";
import Login from "../pages/Login";
import RequireAuth from "../pages/RequireAuth";
import { AnnotationProvider } from "../context/AnnotationContext";
import { Navigate } from "react-router-dom";


export const routes = [
    {
        path: '/login',
        element: <Login/>
    },
    {
        path: '/',
        element: <Navigate to="/reports"/>
    },
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: 'links',
                element: <Home/>
            },

            {
                path: 'reports',
                element: <RequireAuth><Reports/></RequireAuth> ,
            },
            {
                path: 'annotations/:uuid',
                element: <RequireAuth><AnnotationProvider><Annotations/></AnnotationProvider></RequireAuth>,
            },

            {
                path: 'test',
                element: <TestAuth/>
            },


            // no match link
            {
                path: '*',
                element: <NotFound/>
            }
        ]
    },
    
]