import React from 'react'
import ElevationWrapper from '../UI/wrappers/ElevationWrapper'
import ImageQualitySelect from '../ImageQualitySelect/ImageQualitySelect'
import RoomTypeSelector from '../RoomTypeSelector/RoomTypeSelector'
import CustomButton from '../UI/buttons/CustomButton'
import DamageTypeSelector from '../DamageTypeSelector'
import { Eye, EyeSlash } from 'react-bootstrap-icons'
import { ControlsContainer } from './AnnotationControls.styles'

const AnnotationControls = ({ showOriginal, handleToggleOriginal }) => {

    return (
        <ElevationWrapper>
            <ControlsContainer>
                <div className="d-flex flex-row gap-2">
                    <ImageQualitySelect/>
                    <RoomTypeSelector/>
                </div>
                
                <CustomButton type='third' fullWidth={true} onClick={handleToggleOriginal}>
                    {showOriginal 
                        ? (
                            <>
                                <span>Original asset</span>
                                <Eye size={24}/> 
                            </>
                        )
                        : (
                            <>
                                <span>Uploaded asset</span>
                                <EyeSlash size={24}/> 
                            </>
                        )
                    }
                    
                </CustomButton>

                <DamageTypeSelector/>
            </ControlsContainer>
        </ElevationWrapper>
    )
}

export default AnnotationControls
