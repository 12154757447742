import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Rect, Stage } from 'react-konva'
import { useDisplayedImageSize } from '../../hooks/useDisplayedImageSize'
import { Spinner } from 'react-bootstrap'
import AnnotationCanvas from '../AnnotationCanvas'
import { useDispatch, useSelector } from 'react-redux'
import { Layer } from 'react-konva'
import { getDamageColor } from '../../utils/utils'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { ANNOTATION_STATUS_DRAFT } from '../../constants'
import { ImageCanvasContainer } from './AnnotationDrawer.styles'
import { AnnotationContext } from '../../context/AnnotationContext'

const AnnotationDrawer = ( {showOriginal, originalAsset, newAsset } ) => {

    const params = useParams()
    const dispatch = useDispatch()
    const imageRef = useRef(null)
    const containerRef = useRef(null)
    const stageRef = useRef(null)
    const dims = useDisplayedImageSize(containerRef, imageRef, newAsset ? newAsset.url: null)

    const user = useSelector((state) => state.users.user)
    const {annotations, config, updateAnnotations} = useContext(AnnotationContext)

    const imageAnnotations = useMemo(() => {
        return annotations.filter(anno => anno.assetId === newAsset.id) || []
    }, [newAsset, annotations])


    const [currentlyDrawing, setCurrentlyDrawing] = useState(null);
    const currentlyDrawingRef = useRef(currentlyDrawing) // this doesnt seem nice, but yeah...
    
    useEffect(() => {
        currentlyDrawingRef.current = currentlyDrawing
    }, [currentlyDrawing])

    const handleMouseDown = (event) => {
        const {x, y} = event.target.getStage().getPointerPosition();
        const newAnnotation = {
            id: uuidv4(),
            assetId: newAsset.id,
            reportId: params.uuid,
            x: x,
            y: y,
            width: 0,
            height: 0,
            damageClass: config.damageType,
            annotatorName: user.displayName,
            annotatorEmail: user.email,
            // status: ANNOTATION_STATUS_DRAFT
        }
        setCurrentlyDrawing(newAnnotation)
    }

    const handleMouseMove = (event) => {
        if (!currentlyDrawingRef.current) return;
        
        const {x, y} = event.target.getStage().getPointerPosition();
        
        const startX = currentlyDrawingRef.current.x;
        const startY = currentlyDrawingRef.current.y;

        setCurrentlyDrawing((prev) => {
            return {
                ...prev,
                x: startX,
                y: startY,
                width: x - startX,
                height: y - startY
            }
        })
    }

    const handleMouseUp = useCallback(() => {
        // Stage has weird borders or wtf, annotation's side closest to border is on top or behind a border
        if (!currentlyDrawingRef.current) return;
        // if (Object.keys(currentlyDrawing).length === 0) return;
        // console.log("GLOBAL MOUSE UP")
        const startX = currentlyDrawingRef.current.x;
        const startY = currentlyDrawingRef.current.y;
        const stage = stageRef.current.getStage();
        const {x, y} = stage.getPointerPosition();
        const clampedX = Math.min(x, dims.width);
        const clampedY = Math.min(y, dims.height);
        
        // Save annotation to drawn annotations
        const savedAnnotation = {
            ...currentlyDrawingRef.current,
            // normalizing
            x: startX / dims.width,
            y: startY / dims.width,
            width: (clampedX - startX) / dims.width,
            height: (clampedY - startY) / dims.width
        }
        // dispatch(addNewAnnotation(savedAnnotation))
        updateAnnotations(savedAnnotation)

        // clear currently drawing annotation
        setCurrentlyDrawing(null)
    }, [dims, dispatch])



    useEffect(() => {
        const handleMouseUpGlobal = (event) => {
            handleMouseUp();
        }

        window.addEventListener('mouseup', handleMouseUpGlobal);

        return () => {
            window.removeEventListener('mouseup', handleMouseUpGlobal);
        }
    }, [handleMouseUp])


    return (
        <ImageCanvasContainer ref={containerRef}>
            {newAsset 
                ?   (   
                        <>  
                            {!showOriginal && (
                                <Stage
                                    ref={stageRef}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    width={dims.width}
                                    height={dims.height}
                                    style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', zIndex:1}}
                                >   
                                    
                                    {/* DYNAMIC */}
                                    {imageAnnotations.length > 0 && (
                                        <Layer key={'DYNAMIC'}>
                                            <AnnotationCanvas annotations={imageAnnotations} imageDims={dims}/>
                                        </Layer>
                                    )}

                                    {/* CURRENTLY DRAWING */}
                                    {currentlyDrawing && (
                                        <Layer key={'DRAWING'}>
                                            <Rect
                                                key={currentlyDrawing.id}
                                                x={parseFloat(currentlyDrawing.x)}
                                                y={parseFloat(currentlyDrawing.y)}
                                                width={parseFloat(currentlyDrawing.width)}
                                                height={parseFloat(currentlyDrawing.height)}
                                                stroke={getDamageColor(currentlyDrawing.damageClass)}
                                                fill='transparent'
                                                strokeWidth={2}
                                            />
                                        </Layer>
                                    )}
                                </Stage>
                            )}
                
                            <img 
                                ref={imageRef} src={showOriginal ? originalAsset.url : newAsset.url} 
                                style={{position:'relative', width:'100%', height:'100%', objectFit:'contain'}}
                                alt='Room asset'
                            />
                        </>
                       
                    )
                :
                (
                    <Spinner animation="border" variant="dark" style={{width: '10vw', height:'10vw', alignSelf:'center', margin: '0 auto'}} />
                )
            }
        </ImageCanvasContainer>
    )
}

export default AnnotationDrawer
