import React, { useCallback, useContext, useMemo, useState } from 'react'
import { AnnotationContext, DEFAULT_IMAGE_QUALITY } from '../../context/AnnotationContext';
import Dropdown from '../Dropdown';
import { OptionWrapper, ToggleWrapper } from './ImageQualitySelect.styles';
import CustomButton from '../UI/buttons/CustomButton';
import CustomCheckbox from '../UI/inputs/CustomCheckbox/CustomCheckbox';


const options = [
    { 
        field: 'lightingDark', 
        label: 'Dark', 
        defaultValue: DEFAULT_IMAGE_QUALITY.lightingDark,
        element: function(value, onChange) {
            return (
                <OptionWrapper>
                    <CustomCheckbox
                        checked={value}
                        label={this.label}
                        onChange={() => onChange(this.field, !value)}
                    />
                </OptionWrapper>
            )
        }
    },
    { 
        field: 'lightingBright', 
        label: 'Bright', 
        defaultValue: DEFAULT_IMAGE_QUALITY.lightingBright,
        element: function(value, onChange) {
            return (
                <OptionWrapper>
                    <CustomCheckbox
                        checked={value}
                        label={this.label}
                        onChange={() => onChange(this.field, !value)}
                    />
                </OptionWrapper>
            )
        }
    },
    { 
        field: 'isBlury', 
        label: 'Photo blurry', 
        defaultValue: DEFAULT_IMAGE_QUALITY.isBlury,
        element: function(value, onChange) {
            return (
                <OptionWrapper>
                    <CustomCheckbox
                        checked={value}
                        label={this.label}
                        onChange={() => onChange(this.field, !value)}
                    />
                </OptionWrapper>
            )
        }
    },
    { 
        field: 'isMatching', 
        label: 'Photos match', 
        defaultValue: DEFAULT_IMAGE_QUALITY.isMatching,
        element: function(value, onChange) {
            return (
                <OptionWrapper>
                    <CustomCheckbox
                        checked={value}
                        label={this.label}
                        onChange={() => onChange(this.field, !value)}
                    />
                </OptionWrapper>
            )
        }
    },
];

const ImageQualitySelect = () => {

    const {config, updateImageQuality} = useContext(AnnotationContext)

    const currentEvalConfig = useMemo(() => {
        const currentID = config.currentAssetID;
        if (currentID && config.imageQuality[currentID]) {
            return {
                ...DEFAULT_IMAGE_QUALITY,
                ...config.imageQuality[currentID]
            }
        }
        return DEFAULT_IMAGE_QUALITY
    }, [config])

    
    const handleChange = useCallback((field, value) => {
        console.log("handleChange", field, value)
        updateImageQuality(config.currentAssetID, {[field]: value})
    }, [config.currentAssetID])

    // console.log("currentEvalConfig", currentEvalConfig)

    return (
        <Dropdown>
            <Dropdown.Toggle>
                <CustomButton dropdownToggle fullWidth={true}>
                    Select asset quality attributes
                </CustomButton>
            </Dropdown.Toggle>
            <Dropdown.Content>
                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                        {options.map(option => {
                            return (
                                <OptionWrapper key={option.field}>
                                    <CustomCheckbox
                                        checked={currentEvalConfig[option.field]}
                                        label={option.label}
                                        onChange={(e) => handleChange(option.field, e.target.checked)}
                                    />
                                </OptionWrapper>
                            )
                        })}
                </div>
            </Dropdown.Content>
        </Dropdown>
    )
}

export default ImageQualitySelect
