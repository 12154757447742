import React from 'react'
import CustomButton from '../components/UI/buttons/CustomButton'
import { useAuthAction } from '../hooks/useAuthAction'

const mockUsers = [
    {
        id: "6d1d391d-9f2f-43ab-b58e-0f1eb7f3887a",
        displayName: "Dexter Morgan",
        username: "dextermorgan",
        email: "dextermorgan@gmail.com"
    },
    {
        id: "4f59439d-8b1a-43d8-a985-f59d106e86b7",
        displayName: "Sleeping Ruslan",
        username: "sleepingruslan",
        email: "sleepingruslan@gmail.com"
    },
    {
        id: "d6dec6e0-7866-42db-b988-5a510dea1242",
        displayName: "Crystal Nucleus",
        username: "crystalnucleus",
        email: "crystalnucleus@gmail.com"
    }
]

const Login = () => {
    const { login } = useAuthAction()

    return (
        <div style={{height:'100vh', width:'100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <div style={{width:'60vw', display:'flex', flexDirection:'column', gap:'2vh'}}>
                <h3 style={{textAlign:'center', margin: 0}}> Choose user for login (MOCK) </h3>
                <hr/>
                <div style={{display:'flex', flexDirection:'column', gap:'1vh'}}>
                    {mockUsers.map(user => (
                        <CustomButton key={user.id} fullWidth={true} onClick={() => {login(user)}}>
                            {user.displayName}
                        </CustomButton>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Login
