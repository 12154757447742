import React from 'react'
import { Container, StyledCheckbox } from './CustomCheckbox.styles'

const CustomCheckbox = ({label, onChange, ...props}) => {
    return (
        <Container>
            {label &&
                <label htmlFor={props.id || props.name} className='fw-400 fs-16 lh-26 text-color-black'> {label} </label>
            }
            <StyledCheckbox
                type="checkbox" 
                {...props}
                onChange={onChange}
            />

        </Container>
    )
}

export default CustomCheckbox
