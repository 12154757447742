import { useCallback, useState } from "react"


export const usePostSingle = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [response, setResponse] = useState(null)
    const [submitStatus, setSubmitStatus] = useState(
        {status: "READY", message: "Ready to be submitted"}
    )

    const submit = useCallback(async (submitFunction, payload) => {
        setLoading(true)
        setSubmitStatus({status: "PENDING", message:"Submitting..."})

        try {
            const result = await submitFunction(payload)
            setResponse(result.data)
            setSubmitStatus({status: "SUCCESS", message:"Submitted successfully"})
            return result.data
        } catch (e) {
            setError(e.message || 'Submission failed');
            setSubmitStatus({status: "FAILED", message:"Failed to submit..."})
            throw e
        } finally {
            setLoading(false)
        }
        
    }, [])

    return [submit, loading, error, response, submitStatus]
}