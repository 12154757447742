import { useEffect, useRef, useState } from "react"

export const useToggleSidebar = (initState = false) => {

    const sidebarRef = useRef(null)

    const [isOpen, setOpen] = useState(initState)
    const handleToggleSidebar = (event) => {
        event.stopPropagation();
        setOpen(!isOpen)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setOpen(false)
                // document.body.style.overflow = '';
            }
        }

        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.addEventListener('click', handleClickOutside);

        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, [isOpen])

    return { isOpen, handleToggleSidebar, sidebarRef}
}
