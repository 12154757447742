import styled from "styled-components";


export const StaticIcon = styled.i`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: 
        transform 0.2s ease-in-out, 
        opacity 0.2s ease-in-out;
`

export const AnimatedIcon = styled(StaticIcon).withConfig({
    shouldForwardProp: (props) => !['rotate', 'scale', 'visible'].includes(props)
})`
    // Combine transformations
    opacity: ${({visible}) => (visible ? 1 : 0)};
    transform: ${({ rotate, scale }) => (`rotate(${rotate || 0}deg) scale(${scale || 1})`)};

`