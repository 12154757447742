import styled from "styled-components";


export const StyledInput = styled.input.withConfig({
    shouldForwardProp: props => !['fullWidth'].includes(props)
})`
    position: relative;
    width: ${({fullWidth}) => fullWidth ? '100%' : 'auto'};
    padding: 0.5vh 1vw;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 1vh;
    color: var(--text-color);
    outline: none;
    transition: 
        border-color 0.3s ease,
        outline 0.15s ease;

    
    &:focus, &:target, &:active {
        border-color: var(--secondary-color);
    }
    
    &:focus-visible {
        outline: 4px double var(--secondary-color);
    }
`