import React from 'react'
import { Container } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import CustomNavbar from '../components/Navbar'

const Layout = () => {
    return (
        <div style={{width: '100%', padding: 0, minHeight: '100vh', display: 'flex', flexDirection:'column'}}>
            <CustomNavbar/>
            
            <Outlet/>

            <Footer/>

        </div>
    )
}

export default Layout
