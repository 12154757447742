import React, {useContext, useState} from 'react'
import { DAMAGE_TYPES } from '../../constants'
import { FilterRow, FilterName, RotatingIcon, IconWrapper, FiltersContainer } from './AnnotationFilter.styles'
import { AnnotationContext } from '../../context/AnnotationContext'

const AnnotationFilter = () => {

    const {config, updateConfig} = useContext(AnnotationContext)

    const handleFilterVisibility = (value) => {
        const newFilters = new Set(config.filters);
        if (newFilters.has(value)) {
            newFilters.delete(value)
        } else {
            newFilters.add(value)
        }
        updateConfig('filters', Array.from(newFilters))
    } 

    const handleToggleAllFilters = () => {
        if (config.filters.length > 0) {
            updateConfig('filters', [])
        } else {
            updateConfig('filters', DAMAGE_TYPES)
        }
    }

    return (
        <>
            <h4 style={{margin: 0, textAlign:'center'}}> Damage type filters </h4>
            <hr/>
            <FiltersContainer>
                {DAMAGE_TYPES.map((type, index) => {
                    return (
                        <FilterDropdown key={index+1} filterName={type} filterValue={type} level={1} handleAction={handleFilterVisibility}/>
                    )
                })}
                <hr/>
                <AllFilterDropdown filterName={'TOGGLE ALL'} level={1} handleAction={handleToggleAllFilters}/>
            </FiltersContainer>
        </>
    )
}

export default AnnotationFilter


// TODO: Seriously refactor this logic, its NOT REUSABLE. fine for now but its SHIT....
// 
// 
const FilterDropdown = ( {filterName, filterValue, level, children, handleAction} ) => {
    const {config} = useContext(AnnotationContext)
    const visibleDamageTypes = new Set(config.filters);
    const isVisible = visibleDamageTypes.has(filterValue)   

    const handleFilterAction = () => {
        handleAction(filterValue)
    }

    const [isOpen, setOpen] = useState(false)
    const handleToggleDropdown = () => {
        setOpen(!isOpen);
    }

    return (
        // FILTER Wrapper 
        <div style={{
            display:'flex', flexDirection:'column'
        }}> 
            {/* FILTER ROW  */}
            <FilterRow visible={isVisible}>
                <IconWrapper onClick={handleFilterAction}>
                    {isVisible
                        ? <i className='bi bi-eye-fill'/>
                        : <i className="bi bi-eye-slash-fill"></i>
                    }
                </IconWrapper>
                <FilterName level={level} onClick={handleToggleDropdown}>
                    {children && 
                        <RotatingIcon open={isOpen}>
                            <i className="bi bi-chevron-right"></i>
                        </RotatingIcon>
                    }
                    <div> {filterName} </div>
                </FilterName>
                {level < 3
                        ? <></>
                        : <i className="bi bi-pencil" style={{padding:' 0.5vh 1vw'}}></i>
                }
            </FilterRow>
            {isOpen && children}
        </div>
    )
}

const AllFilterDropdown = ( {filterName, level, children, handleAction} ) => {
    const {config} = useContext(AnnotationContext)
    const isVisible = config.filters.length > 0 ? true : false;

    const handleFilterAction = () => {
        handleAction()
    }

    const [isOpen, setOpen] = useState(false)
    const handleToggleDropdown = () => {
        setOpen(!isOpen);
    }

    return (
        // FILTER Wrapper 
        <div style={{
            display:'flex', flexDirection:'column'
        }}> 
            {/* FILTER ROW  */}
            <FilterRow visible={isVisible}>
                <IconWrapper onClick={handleFilterAction}>
                    {isVisible
                        ? <i className='bi bi-eye-fill'/>
                        : <i className="bi bi-eye-slash-fill"></i>
                    }
                </IconWrapper>
                <FilterName level={level} onClick={handleToggleDropdown}>
                    {children && 
                        <RotatingIcon open={isOpen}>
                            <i className="bi bi-chevron-right"></i>
                        </RotatingIcon>
                    }
                    <div> {filterName} </div>
                </FilterName>
                {level < 3
                        ? <></>
                        : <i className="bi bi-pencil" style={{padding:' 0.5vh 1vw'}}></i>
                }
            </FilterRow>
            {isOpen && children}
        </div>
    )
}