import React, {useCallback, useEffect, useRef, useState} from 'react'
import { ToggleContent, DropdownContainer, Content } from './Dropdown.styles'


const DropdownToggle = ({toggleDropdown, children}) => {
    return (
        <ToggleContent>
            {React.Children.map(children, child => {
                if (child.props.dropdownToggle) {
                    return React.cloneElement(child, {onClick: toggleDropdown})
                }
                return child;
            })}
        </ToggleContent>
    )
} 
DropdownToggle.displayName = 'DropdownToggle';


const DropdownContent = ({children, open}) => {
    const contentRef = useRef(null)
 
    return (
        <Content ref={contentRef} isOpen={open}>
            {children}
        </Content>
    )
}
DropdownContent.displayName = "DropdownContent";

const Dropdown = ({children}) => {
    const [open, setOpen] = useState(false)
    const contentRef = useRef(null)
    const handleToggleDropdown = useCallback(() => {
        setOpen(prev => !prev)
        
    }, [])

    const dropdownRef = useRef(null)
    const handleClickOutside = useCallback((event) => {
        if (open && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false)
        }
    }, [open])



    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [handleClickOutside])

    return (
        <DropdownContainer ref={dropdownRef}>
            {React.Children.map(children, child => {
                if (child.type.displayName === 'DropdownToggle') {
                    return React.cloneElement(child, {toggleDropdown: handleToggleDropdown})
                }

                if (child.type.displayName === 'DropdownContent' && open) {
                    return React.cloneElement(child, {open: open});
                }
                return null
            })}
        </DropdownContainer>
    )
}

Dropdown.Toggle = DropdownToggle;
Dropdown.Content = DropdownContent
export default Dropdown

