import styled, {keyframes} from "styled-components";

const scaleIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const StyledCheckbox = styled.input.attrs({type: 'checkbox'})`
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid rgba(200, 200, 200, 1);
    border-radius: 2px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    &:checked {
        background-color: white;
    }

    &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        background-color: #4b5580;
        transform: scale(0);
        opacity: 0;
        transition: all 0.3s ease-out;
    }

    &:checked::before {
        transform: scale(1);
        opacity: 1;
        animation: ${scaleIn} 0.15s ease-in-out;
    }

    &::-ms-check {
        display: none;
    }

`