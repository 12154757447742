import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { getBackgroundColor } from "../../../../utils/utils";



export const StyledBadge = styled(Badge).withConfig({
    shouldForwardProp: (props) => !['type'].includes(props)
})`
    color: var(--text-color)!important;
    background-color: ${({type}) => (getBackgroundColor(type))}!important;
`