import React from 'react'
import { useRoutes } from 'react-router-dom'
import { routes } from './router'

const AppRouter = () => {
    const recursiveRoutes = useRoutes(routes)
    return recursiveRoutes
}

export default AppRouter
