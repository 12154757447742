import React, { useCallback, useContext, useMemo } from 'react'
import { StepperContainer, StepperControls } from './CustomStepper.styles'
import RoundButton from '../UI/buttons/RoundButton'
import { AnnotationContext } from '../../context/AnnotationContext'
import { Check } from 'react-bootstrap-icons'

const CustomStepper = ({data, currentStep, handleStep}) => {

    const {config} = useContext(AnnotationContext)

    const isReviewed = useCallback((currentID) => {
        return config.imageQuality?.[currentID]?.reviewed || false
    }, [config.imageQuality])


    return (
        <StepperContainer>
            <StepperControls>
                {data.map((element, index) => (
                    <RoundButton 
                        key={element.id} type='primary' onClick={() => handleStep(index)}
                        active={index === currentStep}
                        className={isReviewed(element.newPhoto.id) ? 'step-finished': ''}
                    >
                        {isReviewed(element.newPhoto.id)
                            ? <Check size={24}/>
                            : index + 1
                        }
                        
                        
                    </RoundButton>
                ))}
            </StepperControls>
        </StepperContainer>
    )
}

export default React.memo(CustomStepper)
