import styled from "styled-components";


export const CarouselContainer = styled.div`
    position: relative;
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    alig-items: stretch; 
    gap: 1vw;
`
export const ControlButton = styled.button`
    position: relative;
    flex-grow:1;
    color: var(--text-color);
    margin: 0;
    padding: 0.5vw 1vh;
    border: 1px solid rgba(var(--primary-color-rgb), 0.6);
    background-color: rgba(var(--neutral-dark-rgb), 0.4);
    box-shadow: 
        0 5px 20px rgba(var(--neutral-dark-rgb), 0.8);
        // 0 0 40px rgba(var(--neutral-dark-rgb), 0.8) inset;
    backdrop-filter: blur(10px);
    border-radius: 1vh; 
    transition: 
        background-color 0.3s ease-in-out,
        border 0.3s ease-in-out;


    &:hover {
        border: 1px solid transparent;
        background-color: rgba(var(--primary-color-rgb), 0.4);
    }

    // &:before, &:after {
    //     content:"";
    //     position:absolute;
    //     z-index:-1;
    //     box-shadow:0 0 20px rgba(17, 23, 21, 1);
    //     top:0;
    //     bottom:0;
    //     left:10px;
    //     right:10px;
    //     border-radius: 1vh;
    // }

    // &:after {
    //     right:10px;
    //     left:auto;
    //     -webkit-transform:skew(8deg) rotate(3deg);
    //     -moz-transform:skew(8deg) rotate(3deg);
    //     -ms-transform:skew(8deg) rotate(3deg);
    //     -o-transform:skew(8deg) rotate(3deg);
    //     transform:skew(8deg) rotate(3deg);
    // }
`