import axios from "axios";
import { API_KEY, API_URL } from "../constants";

const headers_key = {
    "Content-Type": "application/json",
    "ER-Api-Key": API_KEY,
};

// mock annotations from db
const fetchedAnnotations = [
    {
        id: "adfe57ea-2ca9-4d84-b70c-f16e5d6af2b2",
        assetId: "681157a6-60b6-4d6d-ac6f-3e4946557ee7",
        reportId: "d6255b8a-7c0b-409a-9168-51564a8fcf08",
        x: "0.22",
        y: "0.14",
        width: "0.5",
        height: "0.5",
        damageClass: "SCRATCH",
        annotatorName: "Ivan",
        annotatorEmail: "ivan@gmail.com",
    },
    {
        id: "adfe57ea-2ca9-4d84-b70c-f16e5d6af2b5",
        assetId: "f02564d7-bafd-4e94-befe-0fa5e8a49ac5",
        reportId: "d6255b8a-7c0b-409a-9168-51564a8fcf08",
        x: "0.12",
        y: "0.24",
        width: "0.3",
        height: "0.3",
        damageClass: "SPOT",
        annotatorName: "Vasily",
        annotatorEmail: "vasily@gmail.com",
    },
];

export default class EasyRentorService {
    static async getAllReports() {
        const response = await axios.get(`${API_URL}/reports/dto`, {
            headers: {
                ...headers_key,
            },
        });
        return response;
    }

    static async getPhotosByContractID(uuid) {
        const response = await axios.get(
            `${API_URL}/contracts/${uuid}/photos`,
            {
                headers: {
                    ...headers_key,
                },
            }
        );
        return response;
    }

    static async getAnnotationsByContractID(uuid) {
        const response = await axios.get(
            `${API_URL}/reports/${uuid}/annotations`,
            {
                headers: {
                    ...headers_key,
                },
            }
        );
        return response;
    }
    static async getReportAssetPairsByID(uuid) {
        const response = await axios.get(
            `${API_URL}/reports/${uuid}/photo-pairs-unpacked`,
            {
                headers: {
                    ...headers_key,
                },
            }
        );
        return response;
    }

    // POST

    static async submitAnnotation(annotation) {
        try {
            const response = await axios.post(
                `${API_URL}/annotations`,
                annotation,
                {
                    headers: {
                        ...headers_key,
                    },
                }
            );
            return response;
        } catch (e) {
            throw new Error(
                e.response?.data?.message || "Failed to submit annotation"
            );
        }
    }

    static async batchSubmitAnnotations(annotations) {
        try {
            const response = await axios.post(
                `${API_URL}/annotations/batch`,
                annotations,
                {
                    headers: {
                        ...headers_key,
                    },
                }
            );
            return response;
        } catch (e) {
            throw new Error(
                e.response?.data?.message ||
                    "Failed to submit batch annotations"
            );
        }
    }

    static async batchSubmitPhotos(photos) {
        try {
            const response = await axios.post(
                `${API_URL}/photos/batch`,
                photos,
                {
                    headers: {
                        ...headers_key,
                    },
                }
            );
            return response;
        } catch (e) {
            throw new Error(
                e.response?.data?.message || "Failed to submit batch photos"
            );
        }
    }
    static async submitReport(report) {
        try {
            const response = await axios.post(`${API_URL}/reports`, report, {
                headers: {
                    ...headers_key,
                },
            });
            return response;
        } catch (e) {
            throw new Error(
                e.response?.data?.message || "Failed to submit report"
            );
        }
    }

    // test google auth
    // https://api.easyrentor.com/oauth2/authorization/google
    // https://api.easyrentor.com/api/v1/users/current
    static async Auth() {
        const response = await axios.post(
            `https://api.easyrentor.com/api/v1/oauth2/authorization/google`,
            {
                headers: {
                    ...headers_key,
                },
            }
        );
        return response;
    }
}
