import styled from "styled-components";
import { getBackgroundColor } from "../../../../utils/utils";


export const StyledRoundButton = styled.button.withConfig({
    shouldForwardProp: (props) => !['bg', 'active'].includes(props)
})`
    width: 4vh;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${props => (props.active ? getBackgroundColor(props.type) : 'transparent')};
    box-sizing: border-box;
    color: var(--text-color);
    border: 1px solid ${({bg}) => getBackgroundColor(bg)};
    transition: background 0.25s ease;

    &:hover {
        background-color: var(--primary-color);
    }
`