import React from 'react'

const TestAuth = () => {
    const testAuth = () => {
        window.location.href = 'https://api.easyrentor.com/oauth2/authorization/google'
    }

    return (
        <>
            <button onClick={testAuth}>
                Auth
            </button>
        </>
    )
}

export default TestAuth
