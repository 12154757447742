import styled from "styled-components";

export const ImageCanvasContainer = styled.div`
    position: relative;
    width: 55vw;
    max-width: 55vw;
    display: flex;
    aspect-ratio: 16/9;
    border: 1px solid rgba(var(--primary-color-rgb), 0.6);
    background-color: rgba(var(--neutral-dark-rgb), 0.5);
    background-image: 
        radial-gradient(rgba(var(--primary-color-rgb), 0.4) 1px, transparent 1px),
        radial-gradient(rgba(var(--secondary-color-rgb), 0.4) 1px, transparent 1px);
    background-size: 10px 10px;
    background-position: 0 0, 5px 5px;
    box-shadow: 
        0 5px 20px rgba(var(--neutral-dark-rgb), 0.8),
        0 0 40px rgba(var(--neutral-dark-rgb), 0.8) inset;
`

