import { ACTION_ADD_ANNOTATION, ACTION_REMOVE_ANNOTATION, ACTION_RESET_ALL_ANNOTATIONS, 
    ACTION_RESET_DYNAMIC_ANNOTATIONS, 
    ACTION_RESET_STATIC_ANNOTATIONS, 
    ACTION_SET_ANNOTATION_FILTERS, ACTION_SET_DRAWING_DAMAGE_TYPE, ACTION_SET_STATIC_ANNOTATIONS, 
    ACTION_SET_DAMAGE_TYPE_FILTERS, 
    ACTION_TOGGLE_DAMAGE_TYPE_FILTER, 
    DAMAGE_TYPES,
    ACTION_SET_ANNOTATION_STATUS,
    SET_OPERATION_MODE,
    OPERATION_MODE_IDLE
} from "../constants"


let initialAnnotationState = {
    error: null,
    mode: OPERATION_MODE_IDLE,
    dynamicAnnotations: {},
    drawingDamageType: DAMAGE_TYPES[0],
    annotationsFilter: {
        username: [],
        damageType: DAMAGE_TYPES,
        annotation_id: [],
    },
}

const organizeAnnotations = (annotations) => {
    // console.log("raw annotations", annotations)
    const organized = {};
    annotations.forEach(anno => {
        const {assetId, annotatorEmail, damageClass} = anno;
        // console.log("destructurizing", assetId, annotatorEmail, damageClass)
        if (!organized[assetId]) organized[assetId] = {};
        // console.log(`no set anno with key: ${assetId}. Setting empty {}`)
        if (!organized[assetId][annotatorEmail]) organized[assetId][annotatorEmail] = {};
        // console.log(`no set anno with key: ${annotatorEmail}. Setting empty {}`)
        if (!organized[assetId][annotatorEmail][damageClass]) organized[assetId][annotatorEmail][damageClass] = [];
        // console.log(`no set anno with key: ${damageClass}. Setting empty {}`)
        organized[assetId][annotatorEmail][damageClass].push({...anno, x: parseFloat(anno.x), y: parseFloat(anno.y)});
    });
    // console.log("organized annotations", organized)

    return organized
}



const removeAnnotation = (state, annotationToRemove) => {
    const {id, reportId, assetId, annotatorEmail, damageClass} = annotationToRemove;

    return {
        ...state.dynamicAnnotations,
        [reportId]: {
            ...state.dynamicAnnotations[reportId],
            [assetId]: {
                ...state.dynamicAnnotations[reportId][assetId],
                [annotatorEmail]: {
                    ...state.dynamicAnnotations[assetId][annotatorEmail],
                    [damageClass]: state.dynamicAnnotations[reportId][assetId][annotatorEmail][damageClass].filter(
                        (anno) => anno.id !== id
                    )
                },
            },
        }
    } 
}

const annotationsReducer = (state = initialAnnotationState, action) => {
    switch (action.type) {
        case ACTION_ADD_ANNOTATION: {
            const {reportId, assetId, annotatorEmail, damageClass} = action.payload;
            return {
                ...state,
                dynamicAnnotations: {
                    ...state.dynamicAnnotations,
                    [reportId]: {
                        ...state.dynamicAnnotations[reportId],
                        [assetId]: {
                            ...(state.dynamicAnnotations[reportId]?.[assetId] || {}),
                            [annotatorEmail]: {
                                ...(state.dynamicAnnotations[reportId]?.[assetId]?.[annotatorEmail] || {}),
                                [damageClass]: [
                                    ...(state.dynamicAnnotations[reportId]?.[assetId]?.[annotatorEmail]?.[damageClass] || []),
                                    action.payload,
                                ],
                            },
                        },
                    }
                    
                },
            };
        }
        case ACTION_REMOVE_ANNOTATION: {
            const {id, reportId, assetId, annotatorEmail, damageClass} = action.payload;
            console.log("REMOVE ANNOTATION", action.payload)
            return {
                ...state,
                dynamicAnnotations: {
                    ...state.dynamicAnnotations,
                    [reportId]: {
                        ...state.dynamicAnnotations[reportId],
                        [assetId]: {
                            ...(state.dynamicAnnotations[reportId]?.[assetId] || {}),
                            [annotatorEmail]: {
                                ...(state.dynamicAnnotations[reportId]?.[assetId]?.[annotatorEmail] || {}),
                                [damageClass]: state.dynamicAnnotations[reportId][assetId][annotatorEmail][damageClass].filter(
                                    (anno) => anno.id !== id
                                )
                            },
                        },
                    }
                }
            } 
        }
        case ACTION_SET_ANNOTATION_STATUS: {
            const {reportId, assetId, annotatorEmail, damageClass, id, status} = action.payload;
            return {
                ...state,
                dynamicAnnotations: {
                    ...state.dynamicAnnotations,
                    [reportId]: {
                        ...state.dynamicAnnotations[reportId],
                        [assetId]: {
                            ...(state.dynamicAnnotations[reportId]?.[assetId] || {}), // optional chaining in case something HORRIBLE happens
                            [annotatorEmail]: {
                                ...(state.dynamicAnnotations[reportId]?.[assetId]?.[annotatorEmail] || {}),
                                [damageClass]: state.dynamicAnnotations[reportId][assetId][annotatorEmail][damageClass].map(anno =>
                                    anno.id === id ? {
                                        ...anno,
                                        status: status
                                    } : anno
                                )
                            },
                        },
                    }
                    
                },
            }
        }
        case ACTION_RESET_ALL_ANNOTATIONS: {
            return {
                ...state,
                staticAnnotations: initialAnnotationState.staticAnnotations,
                dynamicAnnotations: initialAnnotationState.dynamicAnnotations
            }
        }
        case ACTION_RESET_STATIC_ANNOTATIONS: {
            return {
                ...state,
                staticAnnotations: initialAnnotationState.staticAnnotations
            }
        }
        case ACTION_RESET_DYNAMIC_ANNOTATIONS: {
            return {
                ...state,
                dynamicAnnotations: initialAnnotationState.dynamicAnnotations
            }
        }
        case ACTION_SET_DRAWING_DAMAGE_TYPE: {
            return {
                ...state,
                drawingDamageType: action.payload
            }
        }
        case ACTION_TOGGLE_DAMAGE_TYPE_FILTER: {
            const damageType = action.payload;
            const damageTypeFilters = new Set(state.annotationsFilter.damageType);
            if (damageTypeFilters.has(damageType)) {
                damageTypeFilters.delete(damageType)
            } else {
                damageTypeFilters.add(damageType)
            }
            return {
                ...state,
                annotationsFilter: {
                    ...state.annotationsFilter,
                    damageType: Array.from(damageTypeFilters)
                    
                }
            }
        }
        case ACTION_SET_DAMAGE_TYPE_FILTERS: {
            const damageTypes = state.annotationsFilter.damageType;
            return {
                ...state,
                annotationsFilter: {
                    ...state.annotationsFilter,
                    damageType: damageTypes.length > 0 ? [] : initialAnnotationState.annotationsFilter.damageType
                }
            }
        }
        


        // OPERATION MODE
        // TODO: made it to separate file 
        case SET_OPERATION_MODE: {
            return {
                ...state,
                mode: action.payload
            }
        }
        default:
            return state;
    }
}

export default annotationsReducer;
