import styled from "styled-components";
import { Link } from "react-router-dom";

export const NavbarContainer = styled.div`
    position: static;
    width: 100%;
    padding: 0;
    height: 64px;
    min-height: 64px;
    top: 0;
    left: 0;
    background-color: var(--neutral-dark);
`

export const NavbarWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2vw;
    color: var(--text-color);
`

export const NavbarBrand = styled.div`
    display: flex;
    height:100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0.5vw 1vh;
    font-size: 2.5vh;
    transition: all 0.3s ease;
    &:hover {
        background-color: rgba(95, 75, 182, 0.6);
        cursor: pointer;
    }
`

export const NavbarItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: underline;
    }
`;

export const StyledIcon = styled.i`
    display: flex;
    align-items:center;
    justify-content: center;
    margin: 0;
    padding: 0;
`

export const UserActionsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;

`

export const UserAction = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5vw;
    padding: 0.5vw 1vh;
    background-color:transparent;
    transition: all 0.3s ease;

    
    &:hover {
        background-color: rgba(95, 75, 182, 0.3);
        cursor: pointer;
    }
`