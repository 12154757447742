import styled from "styled-components";
import { Button } from "react-bootstrap";


export const SidebarContainer = styled.div.withConfig({
    shouldForwardProp: (props) => !['side'].includes(props)
})`
    position: fixed;
    ${(props) => props.side}:0;
    height: calc(100vh - 64px - 30px);
    max-width: 45vw;
    display: flex;
    flex-direction: ${props => props.side === 'left' ? 'row-reverse' : 'row'};
    justify-content: center;
    align-items:center;
    z-index: 3;
`

export const ToggleButton = styled(Button).withConfig({
    shouldForwardProp: (props) => !['active'].includes(props)
})`
    height: 100%;
    max-width: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary-color);
    color: var(--text-color);
    background-color: ${props => (props.active ? 'var(--primary-color)': 'transparent')};
    transition: background-color 0.4s ease;

    &:hover {
        background-color: var(--primary-color); 
        border: 1px solid var(--primary-color);
    }
`

export const RotatingIcon = styled.div.withConfig({
    shouldForwardProp: (props) => !['open'].includes(props)
})`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.2s ease-in-out;
`

export const SidebarContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 0;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    padding: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, width 0.3s ease, padding 0.3s ease;
`