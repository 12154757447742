import styled from "styled-components";


export const FiltersContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5vh;
    height: 100%;
    width: 100%;
`

export const FilterName = styled.div.withConfig({
    shouldForwardProp: (prop) => !['filterLevel'].includes(prop),
})`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    margin: 0;
    padding: 0.5vh 1vw;
    margin-left: ${props => ((props.filterLevel - 1) * 2)}vw;
    transition: all 0.3s ease;

    div {
        margin-left: 0.5vw;
    }

    &:hover {
        background-color: rgba(126, 82, 160, 0.6);
        cursor: pointer;
    }
`

export const IconWrapper = styled.div`
    width: 36px;
    height: 36px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease;

    &:hover {
        background-color: rgba(126, 82, 160, 0.6);
        cursor: pointer;
    }
`

export const RotatingIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${({open}) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: transform 0.2s ease-in-out;
`

export const FilterRow = styled.div.withConfig({
    shouldForwardProp: (props) => !['visible'].includes(props)
})`
    display: flex; 
    flex-direction: row;
    align-items: center;
    background-color: ${(props) => props.visible ? 'rgba(126, 82, 160, 0.3)' : 'transparent'};
    text-decoration: ${props => props.visible ? 'none' : 'line-through'};
    transition: all 0.2s ease;
`