export class REPORT_STATUS {
    // Private Fields
    static #_EXISTS = "EXISTS";
    static #_WAITING_FOR_ASSETS = "WAITING_FOR_ASSETS";
    static #_WAITING_FOR_ANNOTATING = "WAITING_FOR_ANNOTATING";
    static #_WAITING_FOR_LANDLORD_CHECK = "WAITING_FOR_LANDLORD_CHECK";
    static #_WAITING_FOR_AGREEMENT = "WAITING_FOR_AGREEMENT";
    static #_FINISHED = "FINISHED";

    static get EXISTS() { return this.#_EXISTS; }
    static get WAITING_FOR_ASSETS() { return this.#_WAITING_FOR_ASSETS; }
    static get WAITING_FOR_ANNOTATING() { return this.#_WAITING_FOR_ANNOTATING; }
    static get WAITING_FOR_LANDLORD_CHECK() { return this.#_WAITING_FOR_LANDLORD_CHECK; }
    static get WAITING_FOR_AGREEMENT() { return this.#_WAITING_FOR_AGREEMENT; }
    static get FINISHED() { return this.#_FINISHED; }
}


