import React from 'react'

const ExternalLink = ({href, children, style}) => {
  return (
    <a 
        href={href} target="_blank" rel="noopener noreferrer" style={style} > 
        {children} 
    </a>
  )
}

export default ExternalLink
