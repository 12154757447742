import styled from "styled-components";


export const StepperContainer = styled.div`
    position: relative;
    width:100%; 
    display: flex; 
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2vh;
`

export const StepperControls = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
`