import { ACTION_USER_LOGIN, ACTION_USER_LOGOUT } from "../constants";


export const loginUser = (user) => ({
    type: ACTION_USER_LOGIN,
    payload: user
})

export const logoutUser = () => ({
    type: ACTION_USER_LOGOUT,
})