import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, logoutUser } from "../actions/usersActions";

export const useAuthAction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = (user) => {
        dispatch(loginUser(user));
        navigate("/reports");
    };

    const logout = () => {
        dispatch(logoutUser());
    };

    return { login, logout };
};
