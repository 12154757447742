import styled from "styled-components";


export const Elevation = styled.div.withConfig({
    shouldForwardProp: (props) => !['level'].includes(props)
})`
    position: relative;
    width: 100%;             
    display: flex; 
    flex-direction:row; 
    padding: 0.5vh 1vw;
    align-items:center;
    gap: 2vw;
    background-color: rgba(17, 23, 21, ${({level}) => (level/10)});
    
    box-shadow: 
        0 1px 4px rgba(17, 23, 21, ${({level}) => (level/10)}), 
        0 0 40px rgba(17, 23, 21, ${({level}) => (level/10)}) inset;
    backdrop-filter: blur(10px);
    margin: 2vh 0; 
    border-radius: 1vh; 
    z-index: 1;

    // &:before, &:after {
    //     content:"";
    //     position:absolute;
    //     z-index:-1;
    //     box-shadow:0 0 20px rgba(17, 23, 21, 1);
    //     top:0;
    //     bottom:0;
    //     left:10px;
    //     right:10px;
    //     border-radius: 1vh;
    // }

    // &:after {
    //     right:10px;
    //     left:auto;
    //     -webkit-transform:skew(8deg) rotate(3deg);
    //     -moz-transform:skew(8deg) rotate(3deg);
    //     -ms-transform:skew(8deg) rotate(3deg);
    //     -o-transform:skew(8deg) rotate(3deg);
    //     transform:skew(8deg) rotate(3deg);
    // }
` 