import React from 'react'
import { StyledBadge } from './CustomBadge.styles'
import PropTypes from 'prop-types'

const CustomBadge = ({type, children}) => {
    return (
        <StyledBadge type={type}>
            {children}
        </StyledBadge>
    )
}

CustomBadge.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'third', 'accent', 'success', 'warning', 'danger']),
}

export default React.memo(CustomBadge)
