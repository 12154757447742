import styled from "styled-components";


export const FooterContainer = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 0.5vh 2vw;
    height: 30px;
    background-color: rgba(var(--neutral-dark-rgb), 0.5);
    color: var(--text-color);
`