import React from 'react'
import { useTable, useSortBy, usePagination } from 'react-table';
import StyledTable from '../UI/tables/StyledTable';
import CustomButton from '../UI/buttons/CustomButton';
import { AnimatedIcon } from '../UI/icons/Icons.styles';
import { CellWrapper } from '../UI/tables/StyledTable/StyledTable.styles';

const CustomDataTable = ({columns, data}) => {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // rows...
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 } 
        },
        useSortBy,
        usePagination
    );

    return (
        <>
            <StyledTable {...getTableProps()} responsive={true}>
                <thead>
                    {headerGroups.map(headerGroup => {
                        const {key, ...headerGroupProps} = headerGroup.getHeaderGroupProps()
                        return (
                            <tr key={key} {...headerGroupProps}>
                                {headerGroup.headers.map(column => {
                                const {key, ...columnProps} = column.getHeaderProps(column.getSortByToggleProps())
                                return (
                                    <th key={key} {...columnProps}>
                                        <CellWrapper>
                                            <span>{column.render('Header')}</span>
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <AnimatedIcon className='bi bi-chevron-up' visible={true}/>
                                                        : <AnimatedIcon className='bi bi-chevron-up' rotate={180} visible={true}/>
                                                    : <AnimatedIcon className='bi bi-chevron-up' visible={false}/>}
                                            </span>
                                        </CellWrapper>
                                    </th>
                                )})}
                            </tr>
                        )})}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        const {key, ...rowProps} = row.getRowProps()
                        return (
                            <tr key={key} {...rowProps}>
                                {row.cells.map(cell => {
                                    const {key, ...cellProps} = cell.getCellProps()
                                    return <td key={key} {...cellProps}><CellWrapper>{cell.render('Cell')}</CellWrapper></td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>

            </StyledTable>

            <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', gap:'0.5vw'}}>
                <CustomButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </CustomButton>
                <CustomButton onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </CustomButton>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
        
    )
}

export default CustomDataTable
