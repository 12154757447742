import React from 'react'
import { StyledOption, StyledSelect } from './CustomSelect.styles'

const CustomSelect = ({options, value, defaultValue, onChange, fullWidth, ...props}) => {
    return (
        <StyledSelect {...props} value={value} onChange={onChange} fullWidth={fullWidth}>
            {defaultValue && 
                <StyledOption value="" disabled> {defaultValue} </StyledOption> 
            }
            {options.map(option => (
                <StyledOption
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </StyledOption>
            ))}
        </StyledSelect>
    )
}

export default React.memo(CustomSelect)
