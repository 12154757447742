import React, { useEffect, useState } from "react";

export const useDisplayedImageSize = (containerRef, imageRef, src) => {
    const [dims, setDims] = useState({width: 0, height: 0});


    const handleLoad = () => {
        if (!containerRef.current || !imageRef.current) return

        const containerRect = containerRef.current.getBoundingClientRect();
        const img = imageRef.current;
        const ratio = Math.min(
            containerRect.width / img.naturalWidth,
            containerRect.height / img.naturalHeight,
        );
        const width = img.naturalWidth * ratio;
        const height = img.naturalHeight * ratio;
        setDims({width, height})
    }


    // runs on image src change
    useEffect(() => {

        if (!src) return;

        if (!imageRef.current) return;

        imageRef.current.addEventListener('load', handleLoad)
        if (imageRef.current.complete) {
            handleLoad()
        }
        return () => {
            // probably bad fix
            if (imageRef.current) {
                imageRef.current.removeEventListener('load', handleLoad);
            }
        }
    }, [src]);

    // window resize
    useEffect(() => {
        const handleResize = () => {
            handleLoad()
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    return dims;
};
