import { ACTION_USER_LOGIN, ACTION_USER_LOGOUT } from "../constants"


let initialUserState = {
    user: null,
    isAuthenticated: false,
}


const usersReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case ACTION_USER_LOGIN: {
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true
            }
        }
        case ACTION_USER_LOGOUT: {
            return {
                ...state,
                user: null,
                isAuthenticated: false
            }
        }
        default:
            return state
    }
}

export default usersReducer