import React from 'react'
import { StyledRoundButton } from './RoundButton.styles'
import PropTypes from 'prop-types'

const RoundButton = ( {children, bg, active, onClick, ...props} ) => {
    return (
        <StyledRoundButton {...props} bg={bg} active={active} onClick={onClick} >
            {children}
        </StyledRoundButton>
    )
}

RoundButton.propTypes = {
    active: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'secondary', 'third', 'accent', 'success', 'warning', 'danger']),
    onClick: PropTypes.func
}

export default React.memo(RoundButton)
