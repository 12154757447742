import React from 'react'
import { StyledInput } from './TextInput.styles'

const TextInput = ({placeholder, fullWidth = false, onChange}) => {
    return (
        <StyledInput placeholder={placeholder} fullWidth={fullWidth} onChange={onChange}/>
    )
}

export default TextInput
