import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawingDamageType } from '../../actions/annotationsActions'
import CustomSelect from '../UI/inputs/CustomSelect'
import { DAMAGE_TYPES } from '../../constants'
import { capitalizeString } from '../../utils/utils'
import { AnnotationContext } from '../../context/AnnotationContext'

const options = DAMAGE_TYPES.map(type => (
    {value: type, label: capitalizeString(type)}
))

const DamageTypeSelector = () => {

    const {config, updateConfig} = useContext(AnnotationContext)

    const handleChange = (e) => {
        const damageType = e.target.value;
        // dispatch(setDrawingDamageType(damageType))
        updateConfig('damageType', damageType)
    }


    return (
        <CustomSelect
            fullWidth={false}
            options={options}
            value={config.damageType}
            defaultValue={'Select damage type for drawing'}
            onChange={handleChange}
            // className='flex-grow-1'
        />
    )
}

export default DamageTypeSelector
