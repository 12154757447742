import styled from "styled-components";
import { getBackgroundColor } from "../../../../utils/utils";


export const StyledButton = styled.button.withConfig({
    shouldForwardProp: (props) => !['fullWidth', 'type'].includes(props)
})`
    position: relative;
    width: ${({fullWidth}) => fullWidth ? '100%': 'auto'};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    padding: 0.5vw 1vh;
    background-color: transparent;
    color: var(--text-color);
    border: 1px solid ${({type}) => getBackgroundColor(type)};
    border-radius: 1vh;
    transition: background 0.25s ease;

    &:hover {
        background-color: ${props => props.disabled ? 'inherit' : getBackgroundColor(props.type)};
        cursor: ${props => props.disabled ? 'dafault' : 'pointer'};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`