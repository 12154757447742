import React, {useRef, useState, useEffect, Children, useMemo} from 'react'
import { SidebarContainer, ToggleButton, SidebarContent, RotatingIcon } from './Sidebar.styles'
import PropTypes from 'prop-types'
import './Sidebar.css'
import { useToggleSidebar } from '../../hooks/useToggleSidebar';

// TODO: [REVISIT LATER] this was for Sidebar.Content but handling compound components is pain
// and yet not too crucial to have.  
const Content = React.forwardRef(({ children, active}, ref) => {
    return (
        <SidebarContent ref={ref} className={`SIDEBAR_CONTENT ${active ? 'sidebar-show' : ''}`}>
            {children}
        </SidebarContent>
    );
});


const ToggleIcon = ({active, side}) => {
    return (
        <RotatingIcon open={active}>
            <i className={`bi bi-chevron-bar-${side} h4`} style={{margin: 0}}></i>
        </RotatingIcon>
    )
}

const Sidebar = ({children, align = 'left'}) => {
    
    const {isOpen, handleToggleSidebar, sidebarRef} = useToggleSidebar();

    return (
        <>
            {isOpen && <div className={`sidebar-backdrop`}/>}
            <SidebarContainer side={align}>
                <ToggleButton onClick={handleToggleSidebar} active={isOpen}>
                    <ToggleIcon active={isOpen} side={align} />
                </ToggleButton>
                <SidebarContent ref={sidebarRef} className={isOpen ? 'sidebar-show' : ''}>
                    {children}
                </SidebarContent>
            </SidebarContainer>
        </>
    )
}

Sidebar.propTypes = {
    align: PropTypes.oneOf(['left', 'right'])
}


export default Sidebar;



