import React from 'react'
import { Link } from 'react-router-dom'
import ExternalLink from '../components/UI/links/ExternalLink'

const Home = () => {
    return (
        <div style={{alignSelf:'start', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <h1> Main links </h1>
            <div 
                style={{
                    display:'flex', flexDirection:'column', alignItems:'center', border: '1px solid var(--text-color)', 
                    borderRadius:'0.375rem', padding: '0 2vw'}}
            >
                <Link to={"/annotations"} style={{padding:'0.5vh 2vw'}}> Annotations </Link>
                <ExternalLink style={{padding:'0.5vh 2vw'}} href="https://er-manager.herokuapp.com/api/v1/"> db API </ExternalLink>
                <ExternalLink style={{padding:'0.5vh 2vw'}} href="https://github.com/EasyRentor/"> Github </ExternalLink>
                <ExternalLink style={{padding:'0.5vh 2vw'}} href="https://er-manager.herokuapp.com/swagger-ui/index.html"> Swagger </ExternalLink>
                <ExternalLink style={{padding:'0.5vh 2vw'}} href="https://dbdiagram.io/d/63f0bfef296d97641d81f200"> Database diagram </ExternalLink>
            </div>

        </div>
    )
}

export default Home
