import { createContext, useEffect, useState } from "react";
import { DAMAGE_TYPES } from "../constants";
import { useParams } from "react-router-dom";


export const AnnotationContext = createContext()


export const DEFAULT_IMAGE_QUALITY = {
    roomType: '',
    lightingDark: false,
    lightingBright: false,
    isBlury: false,
    isMatching: true
}

export const filterChangedValues = (dataObj) => {
    return Object.entries(dataObj).reduce((acc, [key, value]) => {
        if (value !== DEFAULT_IMAGE_QUALITY[key]) {
            acc[key] = value;
        }
        return acc;
    }, {});
};

const getInitialState = (reportId, key, defaultValue) => {
    const stored = localStorage.getItem(`report_${reportId}_${key}`)
    if (stored && stored !== "undefined") {
        // console.log(stored, typeof(stored))
        return JSON.parse(stored)
    } 
    return defaultValue
}

export const AnnotationProvider = ({children}) => {
    const {uuid} = useParams()

    const [annotations, setAnnotations] = useState(() => 
        getInitialState(uuid, 'annotations', [])
    )
    const [config, setConfig] = useState(() => 
            getInitialState(uuid, 'config', {
            damageType: DAMAGE_TYPES[0],
            filters: DAMAGE_TYPES,
            currentAssetID: '',
            imageQuality: {}
        })
    )


    // newAnnotation => {id, damageClass, ...}
    const updateAnnotations = (newAnnotation) => {
        setAnnotations((prev) => ([...prev, newAnnotation]))
    }

    // - key: some key from config default state
    // - value: value for that key
    const updateConfig = (key, value) => {
        setConfig((prevConfig) => {
            if (!(key in prevConfig)) {
                console.error(`[ANNOTATION CONTEXT] Invalid config key (${key} : ${value})`)
                return prevConfig
            }

            if (typeof prevConfig[key] === 'object' && !Array.isArray(prevConfig[key])) {
                return {
                    ...prevConfig,
                    [key]: {
                        ...prevConfig[key],
                        ...value
                    }
                }
            }

            return {
                ...prevConfig,
                [key]: value
            }
        })
    }

    const updateImageQuality = (assetID, newQualityData) => {
        setConfig((prevConfig) => {
            return {
                ...prevConfig,
                imageQuality: {
                    ...prevConfig.imageQuality,
                    [assetID]: {
                        ...prevConfig.imageQuality[assetID],
                        ...newQualityData
                    }
                }
            }
        })
    }
   

    useEffect(() => {
        localStorage.setItem(`report_${uuid}_annotations`, JSON.stringify(annotations))

        return () => {
            localStorage.removeItem(`report_${uuid}_annotations`)
        }

    }, [annotations, uuid])

    useEffect(() => {
        localStorage.setItem(`report_${uuid}_config`, JSON.stringify(config))

        return () => {
            localStorage.removeItem(`report_${uuid}_config`)
        }

    }, [config, uuid])

    return (
        <AnnotationContext.Provider value={{annotations, config, updateAnnotations, updateConfig, updateImageQuality}}>
            {children}
        </AnnotationContext.Provider>
    )
}