import React from 'react'
import { StyledButton } from './CustomButton.styles'
import PropTypes from 'prop-types'


const CustomButton = ({children, fullWidth=false, type='primary', onClick, ...props}) => {
    return (
        <StyledButton {...props} type={type} fullWidth={fullWidth} onClick={onClick}>
            { children }
        </StyledButton>
    )
}

CustomButton.propTypes = {
    fullWidth: PropTypes.bool,
    type: PropTypes.oneOf(['primary', 'secondary', 'third', 'accent', 'success', 'warning', 'danger']),
    onClick: PropTypes.func
}

export default React.memo(CustomButton)
