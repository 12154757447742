import React from 'react'
import { Modal } from 'react-bootstrap'

const FullscreenModal = ({show, children}) => {
    return (
        <Modal show={show} centered={true} fullscreen={true}>
            <Modal.Body className='d-flex justify-content-center align-items-center'>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default FullscreenModal
