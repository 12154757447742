import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetching } from "../hooks/useFetching";
import EasyRentorService from "../api/EasyRentorService";
import { Spinner } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import AnnotationFilter from "../components/AnnotationFilter";
import CustomStepper from "../components/CustomStepper";
import ImageCarousel from "../components/ImageCarousel";
import {
    AnnotationContext,
    AnnotationProvider,
    DEFAULT_IMAGE_QUALITY,
    filterChangedValues,
} from "../context/AnnotationContext";
import AnnotationControls from "../components/AnnotationControls/AnnotationControls";
import CustomButton from "../components/UI/buttons/CustomButton";
import { REPORT_STATUS } from "../enums";
import { usePostSingle } from "../hooks/usePostSingle";
import LoadingModal from "../components/LoadingModal/LoadingModal";
import { useNavigationProtection } from "../hooks/useNavigationProtection";

const Annotations = () => {
    const params = useParams();
    const { config, annotations, updateConfig, updateImageQuality } =
        useContext(AnnotationContext);

    const [assetPairs, setAssetPairs] = useState([]);
    const [fetchAssetPairs, loadingAssetPairs, erroAssetPairs] = useFetching(
        async (uuid) => {
            const response = await EasyRentorService.getReportAssetPairsByID(
                uuid
            );
            const data = response.data.content;
            console.log(data);
            setAssetPairs(data);
        }
    );

    useEffect(() => {
        fetchAssetPairs(params.uuid);
    }, [params.uuid]);

    useEffect(() => {
        if (
            assetPairs.length > 0 &&
            Object.keys(config.imageQuality).length === 0
        ) {
            const initialImageQuality = {};
            assetPairs.forEach((pair, index) => {
                initialImageQuality[pair.newPhoto.id] = {
                    ...DEFAULT_IMAGE_QUALITY,
                    reviewed: index === 0,
                };
            });
            updateConfig("imageQuality", initialImageQuality);

            updateConfig("currentAssetID", assetPairs[0].newPhoto.id);
        }
    }, [assetPairs]);

    // Stepper
    const [step, setStep] = useState(0);

    const handleNext = useCallback(() => {
        if (step < assetPairs.length - 1) {
            setStep((prev) => prev + 1);
        }
    }, [assetPairs, step]);

    const handleBack = useCallback(() => {
        if (step > 0) {
            setStep((prev) => prev - 1);
        }
    }, [step]);

    const handleStep = useCallback((step) => {
        setStep(step);
    }, []);

    const [showOriginal, setShowOriginal] = useState(false);
    const toggleOriginal = () => {
        setShowOriginal(!showOriginal);
    };

    useEffect(() => {
        if (assetPairs.length > 0) {
            const currentAssetID = assetPairs[step].newPhoto.id;
            updateConfig("currentAssetID", currentAssetID);
            // const currentImageQualityConfig = config.imageQuality[currentAssetID]
            updateImageQuality(currentAssetID, { reviewed: true });
        }
    }, [step]);

    const isAllReviewed = useMemo(() => {
        if (
            !config.imageQuality ||
            Object.keys(config.imageQuality).length === 0
        ) {
            return false;
        }
        return Object.values(config.imageQuality).every(
            (image) => image.reviewed === true
        );
    }, [config]);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [submitSingle, loading, error, response, submitStatus] =
        usePostSingle();

    const getSubmissionSteps = () => {
        return [
            {
                type: "BATCH_PHOTO",
                submit: EasyRentorService.batchSubmitPhotos,
                prepare: () => {
                    const imageQuality = config.imageQuality;
                    console.log(imageQuality);
                    const newPhotos = assetPairs.map((pair) => pair.newPhoto);
                    console.log(newPhotos);
                    const updatedNewPhotos = newPhotos.map((photo) => {
                        const { reviewed, ...photoQualityEval } =
                            imageQuality[photo.id];
                        const onlyChangedConfig =
                            filterChangedValues(photoQualityEval);
                        console.log(
                            "filtered unchanged",
                            onlyChangedConfig,
                            photo.id
                        );
                        return {
                            ...photo,
                            qualityEval: {
                                ...photo.qualityEval,
                                ...onlyChangedConfig,
                            },
                        };
                    });
                    return updatedNewPhotos;
                },
            },
            {
                type: "BATCH_ANNOTATION",
                submit: EasyRentorService.batchSubmitAnnotations,
                prepare: () => {
                    const preparedAnnotations = annotations.map((anno) => {
                        const { id, ...restAnno } = anno;
                        return restAnno;
                    });
                    return preparedAnnotations;
                },
            },
            {
                type: "REPORT",
                submit: EasyRentorService.submitReport,
                prepare: () => {
                    const preparedReport = {
                        id: params.uuid,
                        annotated: true,
                        status: REPORT_STATUS.WAITING_FOR_LANDLORD_CHECK,
                    };
                    return preparedReport;
                },
            },
        ];
    };

    const submitSequentially = async (steps, initialResults = {}) => {
        let results = { ...initialResults };
        for (const step of steps) {
            const data = step.prepare(results);
            console.log(`prepared type: ${step.type}, data:`, data);
            if (step.shouldSubmit && !step.shouldSubmit()) {
                // So we still have access to field values of this object for next steps submissions.
                // See: contractTemplate
                results[step.type] = data;
                continue;
            }
            // console.log("SHOULD SUBMIT:", step.type, data)
            try {
                if (step.isArray) {
                    results[step.type] = await Promise.all(
                        data.map((item) => submitSingle(step.submit, item))
                    );
                } else {
                    results[step.type] = await submitSingle(step.submit, data);
                }
                console.log(`Submitted ${step.type}:`, results[step.type]);
            } catch (error) {
                console.error(`Error submitting ${step.type}:`, error);
                throw error;
            }
        }
        return results;
    };

    const handleSubmit = async () => {
        setShowModal(true);
        try {
            const results = await submitSequentially(getSubmissionSteps());
            await new Promise((resolve) => setTimeout(resolve, 2000));
            console.log("All data submitted successfully:", results);
            setIsSubmitted(true);
        } catch (error) {
            console.error("Error in submission process:", error);
        } finally {
            setShowModal(false);
        }
    };

    const handleModalExited = useCallback(() => {
        console.log("Modal exited, would navigate to /properties here");
        // Uncomment the next line to actually navigate
        navigate("/reports");
    }, [navigate]);
    // console.log(config)

    return (
        <div
            className="container"
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
            {/* Visibility filters */}
            <Sidebar align={"left"}>
                <AnnotationFilter />
            </Sidebar>

            {/* FIXME: height is fucking retarted, change layout to 3col grid (1, 3 sidebars; 2 main content) not container */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                }}
            >
                <h2 style={{ textAlign: "center", marginTop: "20px" }}>
                    Annotations
                </h2>

                {/* DRAW, EDIT DONE, controls */}
                <AnnotationControls
                    showOriginal={showOriginal}
                    handleToggleOriginal={toggleOriginal}
                />

                {/* Image Carousel */}
                {loadingAssetPairs ? (
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ width: "10vw", height: "10vw" }}
                    />
                ) : (
                    <ImageCarousel
                        showOriginal={showOriginal}
                        currentPair={assetPairs[step]}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                )}

                {/* Stepper */}
                <CustomStepper
                    data={assetPairs}
                    currentStep={step}
                    handleStep={handleStep}
                />
            </div>

            <div
                style={{
                    marginTop: "auto",
                    marginBottom: "2rem",
                }}
            >
                <CustomButton
                    type="success"
                    fullWidth={true}
                    disabled={!isAllReviewed}
                    onClick={handleSubmit}
                >
                    Submit
                </CustomButton>
            </div>

            <LoadingModal show={showModal} onClose={handleModalExited} />
        </div>
    );
};

export default Annotations;
