import React from 'react'
import { 
    NavbarContainer, NavbarItem, NavbarWrapper, StyledIcon, NavbarBrand, UserActionsList,
    UserAction
} from './Navbar.styles'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../Dropdown'
import CustomButton from '../UI/buttons/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthAction } from '../../hooks/useAuthAction'

const CustomNavbar = () => {
    const navigate = useNavigate()
    const {login, logout} = useAuthAction()
    const {user, isAuthenticated} = useSelector((state) => state.users)

    return (
        <NavbarContainer>
            <NavbarWrapper className='container'>
                <NavbarBrand onClick={() => {navigate('/reports')}}>
                    EasyRentor 
                </NavbarBrand>
                <NavbarItem>
                    {isAuthenticated
                        ? (
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <CustomButton dropdownToggle>
                                        {user.email}
                                        <StyledIcon className="bi bi-person-circle"></StyledIcon>
                                    </CustomButton>
                                </Dropdown.Toggle>
                                <Dropdown.Content>
                                    <UserActionsList>
                                        <UserAction> Profile </UserAction>
                                        <CustomButton onClick={logout}>
                                            Logout
                                            <StyledIcon className='bi bi-box-arrow-right'></StyledIcon>
                                        </CustomButton>
                                    </UserActionsList>
                                </Dropdown.Content>
                            </Dropdown>
                        )
                        : (
                            <CustomButton onClick={() => {navigate('/login')}}>
                                Login
                                <StyledIcon className="bi bi-person-circle"></StyledIcon>
                            </CustomButton>
                        )
                    
                    }
                    
                     
                </NavbarItem>
               
            </NavbarWrapper>
        </NavbarContainer>
    )
}

export default CustomNavbar