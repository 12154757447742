import React, { useContext, useMemo } from 'react'
import { Group, Rect } from 'react-konva'
import { getDamageColor } from '../../utils/utils'
import { DAMAGE_TYPES } from '../../constants'
import { AnnotationContext } from '../../context/AnnotationContext'



const AnnotationCanvas = ({ annotations, imageDims }) => {


    const {config} = useContext(AnnotationContext)
    const visibleDamageTypes = new Set(config.filters);

    const damageGroupedAnnotations = useMemo(() => {
        const grouped = annotations.reduce((acc, anno) => {
            acc[anno.damageClass] = acc[anno.damageClass] || []
            acc[anno.damageClass].push(anno)
            return acc
        }, {})
        return grouped
    }, [annotations])

    return (
        <>
            {DAMAGE_TYPES.map((damageType) => {
                if (!visibleDamageTypes.has(damageType)) {
                    return null;
                }
                const damageAnnotations = damageGroupedAnnotations[damageType]
                if (!damageAnnotations || damageAnnotations.length === 0) {
                    return null;
                }
                return (
                    <Group key={damageType}>
                        {damageAnnotations.map((anno) => {
                        //   console.log("RECT", anno.id, anno.x, anno.y, anno.width, anno.height);
                            return (
                                <Rect
                                    key={anno.id}
                                    x={parseFloat(anno.x) * imageDims.width}
                                    y={parseFloat(anno.y) * imageDims.width}
                                    width={parseFloat(anno.width) * imageDims.width}
                                    height={parseFloat(anno.height) * imageDims.width}
                                    stroke={getDamageColor(anno.damageClass)}
                                    fill='transparent'
                                    strokeWidth={2}
                                />
                            );
                        })}
                    </Group>
                );
            })}
        </>
        
    )
}

export default React.memo(AnnotationCanvas)


// Old implementation with nested object redux state for
// - username:
//     - damageType
{/* <>
{!isAnnotationsEmpty(annotations) && Object.keys(annotations).map((username) => {
//   console.log("USER GROUP", annotations[username], username);
  return (
    <Group key={username}>
      {Object.keys(annotations[username]).map((damageType) => {
        if (!visibleDamageTypes.has(damageType)) {
            return null;
        }
        // console.log("DAMAGE TYPE GROUP", annotations[username][damageType], damageType);
        return (
          <Group key={`${username}_${damageType}`}>
            {annotations[username][damageType].map((anno) => {
            //   console.log("RECT", anno.id, anno.x, anno.y, anno.width, anno.height);
              return (
                <Rect
                    key={anno.id}
                    x={parseFloat(anno.x) * imageDims.width}
                    y={parseFloat(anno.y) * imageDims.width}
                    width={parseFloat(anno.width) * imageDims.width}
                    height={parseFloat(anno.height) * imageDims.width}
                    stroke={getDamageColor(anno.damageClass)}
                    fill='transparent'
                    strokeWidth={2}
                />
              );
            })}
          </Group>
        );
      })}
    </Group>
  );
})}
</> */}
